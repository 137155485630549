import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --dynamic-blue: #4db1e8;
    --dynamic-dark-blue: #0090de;
    --dynamic-red: #ed5145;
    --dynamic-pressed-red: #ff3525;
    --dynamic-yellow: #ffc700;
    --dynamic-purple: #712ca5;
    --dynamic-dark-grey: #4f4f4f;
    --dynamic-white: #fff;
    --dynamic-black: #000;
    --site-background: #e5e5e5;
  }

  .ot-pc-footer-logo {
    display: none !important;
  }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  /* Scrollbar styling if viewport is over 1460px */
  ${
    '' /* body::-webkit-scrollbar {
    width: 0.625em;
  }
  body::-webkit-scrollbar-track {
    background-color: ${promozooBlack};
  }
  body::-webkit-scrollbar-thumb {
    background-color: ${promozooRed};
    border-radius: 5px;
  } */
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #e5e5e5;
    color: var(--dynamic-dark-grey);
    font-family: montserrat, sans-serif;

    ::-webkit-scrollbar {
      width: 0.813rem;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.31rem grey;
      border-radius: 0.625rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--dynamic-blue);
      border-radius: 0.625rem;
    }
  }

  .ReactModal__Body--open {
    overflow-y: hidden !important;
    z-index: 0 !important;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  .ReactModal__Content--after-open {
    width: 95%;
    ${'' /* height: 80%; */}
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    @media screen and (min-width: 620px) {
      width: 620px;
    }
  }

  .cta-space {
    display: block;
    margin-bottom: 1.063rem;
  }

  a[href^="https://ie.trustpilot.com"] img {
    width: 100%;
  }
`;
